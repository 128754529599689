@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  display: none;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white !important;;
  opacity: 1; /* Firefox */
}

.bg_user_background {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#b7deed+0,6398ce+35,1c97bc+71,b7deed+100&0.89+0,0.89+100 */
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#398d96+0,124572+33,1d7293+70,7659a5+100 */
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#2c3036+0,2f343b+7,31363b+16,303439+17,32353c+17,32353c+19,30343a+22,2e323a+22,30343b+22,2e3239+22,31343b+22,2f3138+23,2f333a+24,2d3036+26,2e3138+26,2a3036+27,2a2e35+31,272c32+31,292c32+33,262a32+33,282b31+34,242a31+35,252930+37,22282f+37,252930+38,20252c+43,22272e+43,1f242c+44,21262d+44,1d222a+47,1d232a+50,1b2128+51,1b2028+52,1c222a+52,1b2028+55,1d2229+55,1a2028+56,1c222a+58,1a2027+58,1c2229+58,1c2229+59,1a2028+59,1c2129+60,192027+62,1b2229+65,1a2129+71,1c232b+72,1a222a+73,1d252c+76,1c232c+79,1d262e+81,1c242d+85,1e262f+86,1c252d+86,1e262f+88,1c252d+88,1c242d+90,1e262e+90,1d242d+92,1f272e+93,1d242c+93,1f262d+93,1c242c+93,1e262e+93,1c232c+95,1e252e+96,1c232b+96,1d252d+96,1d222c+97,1c232b+100 */
background: #2c3036; /* Old browsers */
background: -moz-linear-gradient(-45deg,  #2c3036 0%, #2f343b 7%, #31363b 16%, #303439 17%, #32353c 17%, #32353c 19%, #30343a 22%, #2e323a 22%, #30343b 22%, #2e3239 22%, #31343b 22%, #2f3138 23%, #2f333a 24%, #2d3036 26%, #2e3138 26%, #2a3036 27%, #2a2e35 31%, #272c32 31%, #292c32 33%, #262a32 33%, #282b31 34%, #242a31 35%, #252930 37%, #22282f 37%, #252930 38%, #20252c 43%, #22272e 43%, #1f242c 44%, #21262d 44%, #1d222a 47%, #1d232a 50%, #1b2128 51%, #1b2028 52%, #1c222a 52%, #1b2028 55%, #1d2229 55%, #1a2028 56%, #1c222a 58%, #1a2027 58%, #1c2229 58%, #1c2229 59%, #1a2028 59%, #1c2129 60%, #192027 62%, #1b2229 65%, #1a2129 71%, #1c232b 72%, #1a222a 73%, #1d252c 76%, #1c232c 79%, #1d262e 81%, #1c242d 85%, #1e262f 86%, #1c252d 86%, #1e262f 88%, #1c252d 88%, #1c242d 90%, #1e262e 90%, #1d242d 92%, #1f272e 93%, #1d242c 93%, #1f262d 93%, #1c242c 93%, #1e262e 93%, #1c232c 95%, #1e252e 96%, #1c232b 96%, #1d252d 96%, #1d222c 97%, #1c232b 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(-45deg,  #2c3036 0%,#2f343b 7%,#31363b 16%,#303439 17%,#32353c 17%,#32353c 19%,#30343a 22%,#2e323a 22%,#30343b 22%,#2e3239 22%,#31343b 22%,#2f3138 23%,#2f333a 24%,#2d3036 26%,#2e3138 26%,#2a3036 27%,#2a2e35 31%,#272c32 31%,#292c32 33%,#262a32 33%,#282b31 34%,#242a31 35%,#252930 37%,#22282f 37%,#252930 38%,#20252c 43%,#22272e 43%,#1f242c 44%,#21262d 44%,#1d222a 47%,#1d232a 50%,#1b2128 51%,#1b2028 52%,#1c222a 52%,#1b2028 55%,#1d2229 55%,#1a2028 56%,#1c222a 58%,#1a2027 58%,#1c2229 58%,#1c2229 59%,#1a2028 59%,#1c2129 60%,#192027 62%,#1b2229 65%,#1a2129 71%,#1c232b 72%,#1a222a 73%,#1d252c 76%,#1c232c 79%,#1d262e 81%,#1c242d 85%,#1e262f 86%,#1c252d 86%,#1e262f 88%,#1c252d 88%,#1c242d 90%,#1e262e 90%,#1d242d 92%,#1f272e 93%,#1d242c 93%,#1f262d 93%,#1c242c 93%,#1e262e 93%,#1c232c 95%,#1e252e 96%,#1c232b 96%,#1d252d 96%,#1d222c 97%,#1c232b 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(135deg,  #2c3036 0%,#2f343b 7%,#31363b 16%,#303439 17%,#32353c 17%,#32353c 19%,#30343a 22%,#2e323a 22%,#30343b 22%,#2e3239 22%,#31343b 22%,#2f3138 23%,#2f333a 24%,#2d3036 26%,#2e3138 26%,#2a3036 27%,#2a2e35 31%,#272c32 31%,#292c32 33%,#262a32 33%,#282b31 34%,#242a31 35%,#252930 37%,#22282f 37%,#252930 38%,#20252c 43%,#22272e 43%,#1f242c 44%,#21262d 44%,#1d222a 47%,#1d232a 50%,#1b2128 51%,#1b2028 52%,#1c222a 52%,#1b2028 55%,#1d2229 55%,#1a2028 56%,#1c222a 58%,#1a2027 58%,#1c2229 58%,#1c2229 59%,#1a2028 59%,#1c2129 60%,#192027 62%,#1b2229 65%,#1a2129 71%,#1c232b 72%,#1a222a 73%,#1d252c 76%,#1c232c 79%,#1d262e 81%,#1c242d 85%,#1e262f 86%,#1c252d 86%,#1e262f 88%,#1c252d 88%,#1c242d 90%,#1e262e 90%,#1d242d 92%,#1f272e 93%,#1d242c 93%,#1f262d 93%,#1c242c 93%,#1e262e 93%,#1c232c 95%,#1e252e 96%,#1c232b 96%,#1d252d 96%,#1d222c 97%,#1c232b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2c3036', endColorstr='#1c232b',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

}